/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6a0b9ffb-4274-42fb-962f-eb687c383ed5",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_h3Hd8PfQl",
    "aws_user_pools_web_client_id": "pq59dlug55sbvmi1jk6u34stp",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nvu6ikaobrbjxfzt7rq4eevopu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ymrke423x5giteuw4eupvqhjrq"
};


export default awsmobile;
